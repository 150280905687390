<template>
  <div class="container">
    <div class="terms-of-service-top-container">
      <Navbar></Navbar>
    </div>
    <div class="policy-container">
      <h1>JIPH&reg; Privacy Policy</h1>
      <div class="content">
        <h2>COMMITMENT TO PRIVACY</h2>
        <p>
          This website is developed and maintained by Global Express Pay LLC
          d/b/a Jiph (hereinafter “Jiph,” “we,” or “us”). The data controller is
          Jiph and can be contacted as explained below in the “How to Contact
          Us” section.
        </p>
        <p>
          When you visit our website, official social media sites, or other
          online properties, collectively “Sites” or “Services,” Jiph may
          collect and use certain information about you. Jiph recognizes that
          you care about how your information is used, and your privacy is
          important to us. This Privacy Policy explains how we may collect and
          use your information, particularly your personal information, and the
          choices you can make about what information you share.
        </p>
        <p>
          This Online Privacy Notice (Notice”) applies to all Services offered
          by Jiph to U.S. consumers, except where a product or service has a
          separate privacy notice that does not incorporate this Notice. Certain
          individuals also may be provided with additional privacy notices as
          described below.
        </p>

        <ul>
          <li>
            <strong>Jiph Customers –</strong>
            When you sign up for an account with Jiph, we may collect and use
            your personal information pursuant to our GLBA Privacy Notice as
            applicable. Federal law requires us to provide notice to our
            customers to explain what personal information is collected, how it
            is shared with nonaffiliated third parties, and when applicable, the
            circumstances under which our customers may limit the sharing of
            information and how to exercise the right to limit information
            sharing. sharing of the information. If you are a Jiph customer,
            please see our GLBA Privacy Notice<small>[LC3]</small> .
          </li>
          <li>
            <strong>Notice to California Residents –</strong>
            If you are a resident of California, please see our California
            Consumer Privacy Statement .
          </li>
        </ul>

        <h2>UPDATES TO OUR PRIVACY POLICY</h2>
        <p>
          We ask that you read this Notice from time to time. Jiph may modify
          Notice at any time at its sole discretion. If we make material changes
          to this Notice that increase our rights to use personal information we
          have previously collected about you, we will first obtain your consent
          where required by law. If Jiph intends to further process collected
          personal data for a purpose other than that for which the personal
          data was collected, prior to that further processing, we shall provide
          you with information on that other purpose with additional information
          necessary to ensure fair and transparent processing.
        </p>

        <h2>INFORMATION WE COLLECT</h2>
        <h3>INFORMATION YOU PROVIDE TO US</h3>
        <p>
          Jiph collects information about you when you interact with our Sites
          and Services. When you use our Sites and Services, we collect
          information about one or more devices associated with you that you use
          to access the Sites, such as a computer, mobile phone, or tablet. We
          collect, store, and use information you provide to us from one or more
          devices associated with you when you use our Sites, including when you
          log into the Sites or Services from one or more of your devices,
          register for an account, complete a web form, add or update your
          account information, apply for a job at Jiph, or otherwise correspond
          with us regarding the Sites. Information that we collect may include:
        </p>
        <ul>
          <li>
            Identifiers such as name, email address, phone number, date of
            birth, physical address, and country of residence, when you create
            an account to log in to our Platform;
          </li>
          <li>
            Login credentials, including username and password, for your Jiph
            Account;
          </li>
          <li>
            When you request Services on our Sites, open an Account, conduct a
            transaction, or use our Sites for any reason, we may collect
            information you provide, including, as necessary, to complete the
            transaction, your name, legal address, last four digits of your SSN,
            and credit or debit card billing information;
          </li>
          <li>
            Biometric information (such as a facial image collected for identity
            verification);
          </li>
          <li>Social media handles;</li>
          <li>
            Voice recordings (such as when you call Jiph’s Customer Service);
          </li>
          <li>
            If you use our Application on your mobile device, we also may
            collect mobile device information like operating system and hardware
            type, numbers or codes that are unique to your particular device,
            device information, default device language, the location of your
            device, and app usage information;
          </li>
          <li>
            Information you provide through customer service interactions and
            that you provide about your experience with Jiph, including
            responses to surveys offered by the Company;
          </li>
          <li>
            If you provide us feedback or contact us via email, we will collect
            your name and email address, as well as any other content included
            in the email, in order to send you a reply;
          </li>
          <li>
            If you tell us where you are by allowing your mobile device to send
            us your location (geolocation data), we may store and use that
            information for fraud prevention purposes;
          </li>
          <li>Your subscriptions to our newsletters;</li>
          <li>
            In addition to the above, we may need to verify your identity in
            order for you to use some aspects of the Service. For purposes of
            verification, we may also collect the following personal information
            from you. If requested for compliance reasons, providing this
            information is mandatory to use our Service:
            <ul>
              <li>
                Valid government issued identification such as a passport or
                driver’s license;
              </li>
              <li>Other identification documents as deemed appropriate;</li>
              <li>
                Tax-related information such as a tax identification number
                (“TIN”) or social security number (“SSN”); and/or
              </li>
              <li>
                ­ Any other information as may be required to verify your
                identity in accordance with applicable laws and regulations;
              </li>
            </ul>
          </li>
          <li>
            We may also collect information at other points in our Sites and
            Services where it is clear we are collecting such information;
            and/or
          </li>
          <li>
            Other information you choose to provide, such as through our
            “Contact Us” feature, emails, or other communications (such as with
            customer service), referrals, on social media pages, or in
            registrations and sign-up forms.<small>[LC5]</small>
          </li>
        </ul>

        <h3>INFORMATION COLLECTED VIA TECHNOLOGY <small>[LC6]</small></h3>
        <h4>Information We Automatically Collect</h4>
        <p>
          We automatically collect information about the devices you use to
          interact with our Sites. We automatically collect your device
          identifier, web browser type and version, IP address, precise
          geolocation, and browsing information collected through cookies and
          beacons. We also automatically collect information about how you use
          the Sites, such as what you have searched for and viewed on the Sites.
          The information automatically collected will be associated with any
          personal information you have provided.
        </p>
        <p>
          We collect certain information by automated means when you visit our
          Sites, such as how many users visited our Sites and the pages
          accessed. By collecting this information, we learn how to best tailor
          our Sites to our visitors. We collect this information through various
          means such as "cookies" and "web beacons."
        </p>
        <h4>Cookies</h4>
        <p>
          Like many companies, we use “cookies” on some of our Sites. Cookies
          are pieces of code placed on your devices when you visit certain
          websites. We use cookies to tell us, for example, whether you have
          visited us before or if you are a new visitor, and to help us identify
          features of the Sites in which you may have the greatest interest.
          Cookies may enhance your online experience by saving your preferences
          while you are visiting a particular website.
        </p>
        <p>
          Most web browsers will tell you how to stop accepting new cookies, how
          to be notified when you receive a new cookie, and how to disable
          existing cookies. Please note, however, that without cookies you may
          not be able to take full advantage of the features and functionality
          of our Sites.
        </p>
        <h4>Web Beacons</h4>
        <p>
          Certain pages on our website contain “web beacons” (also known as
          Internet tags, pixel tags, and clear GIFs). These web beacons allow
          third parties to obtain information, such as the IP address of the
          computer that downloaded the page on which the beacon appears; the URL
          of the page on which the beacon appears; the time the page containing
          the beacon was viewed; the type of browser used to view the page; and
          the information in cookies set by the third party.
        </p>
        <h4>IP Addresses</h4>
        <p>
          An IP address is a unique identifier that certain electronic devices
          use to identify and communicate with each other on the Internet. When
          you visit our website, we view the IP address of the device you use to
          connect to the Internet. We then use this information to determine the
          general physical location of the device and understand the general
          locations of our website visitors. We also use this information to
          enhance our Sites.
        </p>
        <h4>Collection of Data in Connection with Advertising</h4>
        <p>
          We may use third parties to serve ads on the Sites. In connection with
          the delivery of advertising, certain third parties may automatically
          collect information about your visits to this and other websites, your
          IP address, your ISP, and the browser you use to visit our Sites. They
          do this by using cookies, web beacons, or other technologies.
          Information collected may be used, among other things, to deliver
          advertising targeted to your interests and to better understand the
          usage and visitation of our Sites and the other sites tracked by these
          third parties. This policy does not apply to, and we are not
          responsible for, cookies or pixel tags in third party ads, and we
          encourage you to check the privacy policies of advertisers and/or ad
          services to learn about their use of cookies and other technologies.
        </p>
        <h4>Mobile Services</h4>
        <p>
          We may also collect non-personal information from your mobile device
          if you have downloaded our mobile app. This information is generally
          used to help us deliver the most relevant information to you. Examples
          of information that may be collected and used include how you use the
          mobile app, and information about the type of device you use. In
          addition, in the event our mobile app crashes on your mobile device,
          we will receive information about your mobile device model software
          version and device carrier, which allows us to identify and fix bugs
          and otherwise improve the performance of our mobile app.
        </p>
        <h4>Location Information</h4>
        <p>
          If you have enabled location services on your phone, tablet, or other
          mobile device, we may collect your location information including for
          the purpose of verifying whether you are eligible to participate in a
          channel, service, or promotion.
        </p>
        <h4>Information Collected from Other Sources</h4>
        <p>
          We may also collect information about you from third parties, such as
          marketing partners, identity verification services, anti-fraud
          services, and other service providers. We may also supplement the
          information we have about you with information received from other
          sources such as commercially available sources (e.g., data brokers and
          public databases). The information we receive may include demographic
          data such as age, gender, and income level, your interests and
          purchase data and other information that is necessary or useful to
          assist our marketing efforts or for user verification, authentication,
          fraud detection or to comply with regulatory requirements.
        </p>
        <h2>HOW WE USE THE INFORMATION WE COLLECT ABOUT YOU</h2>
        <p>We may use your information for the following purposes:</p>
        <h3>TO COMMUNICATE WITH YOU</h3>
        <ul>
          <li>To provide you with customer support;</li>
          <li>
            To communicate with you about our products and services, including
            promotions and offers;
          </li>
          <li class="no-style">or</li>
          <li>
            To send you further notices, financial news notifications,
            promotions, solicitations, brochures, or other marketing materials
            regarding our Sites, our products, and the services of our
            businesses, affiliates, business partners or authorized dealers.
          </li>
        </ul>
        <h3>TO MANAGE AND IMPROVE OUR BUSINESS OPERATIONS</h3>
        <ul>
          <li>To tailor specific promotions according to your preferences;</li>
          <li>To provide you with access to the Services;</li>
          <li>To serve the functions of the Sites;</li>
          <li>
            To manage everyday business needs, such as administering and
            improving the Sites;
          </li>
          <li>To analyze the performance and functioning of the Sites;</li>
          <li>
            To analyze how you use the Sites and to perform other market
            research;
          </li>
          <li>
            For internal operations, including troubleshooting, testing, and
            analytics; and
          </li>
          <li>
            To assist us in developing new products and improving our Services.
          </li>
        </ul>
        <h3>
          TO ENSURE A SECURE ONLINE ENVIRONMENT AND COMPLY WITH LEGAL
          OBLIGATIONS
        </h3>
        <ul>
          <li>
            Protect against and detect fraud, abuse, or other unlawful activity;
          </li>
          <li>
            Enforce our Terms and Conditions<small>[LC7]</small> , other
            corporate policies, and industry standards; and
          </li>
          <li>Comply with all applicable laws and reporting obligations.</li>
        </ul>
        <h3>WE MAY SHARE YOUR INFORMATION WITH THIRD PARTIES</h3>
        <ul>
          <li>
            To help us operate our business and the Sites or to administer
            activities on our behalf, such as sending out newsletters or surveys
            for those limited purposes provided that you have given us your
            permission;
          </li>
          <li>To verify your eligibility for our services; and</li>
          <li>
            To provide you with offers and marketing that may be of interest to
            you.
          </li>
        </ul>
        <p>
          If you notify us that you do not wish to be contacted for marketing
          purposes, we will not send you marketing information.
        </p>
        <h3>DO NOT TRACK</h3>
        <p>
          Our Sites do not collect personal information about your online
          activities over time and across third-party websites or online
          services. Therefore, “do not track” signals transmitted from web
          browsers do not apply to our Sites, and we do not alter any of our
          data collection and use practices upon receipt of such a signal.
        </p>
        <h3>BUSINESS STRUCTURE CHANGES</h3>
        <p>
          We reserve the right to disclose and transfer all information related
          to our Sites, including personal data:
        </p>
        <ul>
          <li>
            To a subsequent owner, co-owner, or operator of one or more of the
            Sites; or
          </li>
          <li>
            In connection with a corporate merger, consolidation, or
            restructuring, the sale of substantially all of our ownership
            interests and/or assets, or other corporate change, including,
            without limitation, during the course of any due diligence process.
          </li>
        </ul>

        <h2>INFORMATION WE SHARE</h2>
        <p>
          We share your information with third-party service providers who
          perform services and functions on our behalf to support our
          interactions with you including, for example, providing our products
          and services or communicating with you. These third parties are not
          authorized by us to use or disclose the information, except as
          necessary to perform services on our behalf or comply with legal
          requirements. These third parties have no independent rights to the
          data.
        </p>
        <p>In addition, we will disclose information about you:</p>
        <ul>
          <li>If we are required to do so by law or legal process;</li>
          <li>
            To law enforcement authorities or other government officials, as
            necessary;
          </li>
          <li>
            When we believe disclosure is necessary or appropriate to prevent
            physical harm or financial loss or in connection with an
            investigation or suspected or actual illegal activity;
          </li>
          <li>If necessary, to protect the vital interests of a person;</li>
          <li>To enforce our Terms and Conditions<small>[LC8]</small> ;</li>
          <li>To protect our property, services, and legal rights;</li>
          <li>
            To prevent fraud against Jiph, our affiliates, business partners, or
            authorized dealers;
          </li>
          <li>
            To support auditing, compliance, and corporate governance functions;
            or
          </li>
          <li>To comply with any and all applicable laws.</li>
        </ul>
        <p>
          In certain situations, Jiph may be required to disclose personal data
          in response to lawful requests by public authorities, including to
          meet national security or law enforcement requirements.
        </p>

        <h2>SOCIAL NETWORKING</h2>
        <p>
          This website allows users to sign into and associate social media
          accounts, including but not limited to, Facebook and LinkedIn, with
          the Sites and Services. By using this functionality, you give Jiph
          permission to access all of the elements of your social network
          profile information that you have made available to be shared and to
          use it in accordance with the social network’s terms of use and this
          Privacy Policy. Please refer to the privacy settings in your social
          network account for information about what data is shared with Jiph
          and other connected applications and to manage the data that is shared
          through your account, including information about your activities
          using our Sites.
        </p>
        <p>
          Jiph does not retain your username or password for social networking
          platforms for any longer than is necessary to complete an interaction.
          If you would like to disconnect a social media account from Jiph,
          please refer to the settings of that social media account and its
          provider.<small>[LC9]</small>
        </p>

        <h2>LINKS TO OTHER WEBSITES</h2>
        <p>
          Our Sites may provide links to other websites for your convenience and
          information. These websites may operate independently from our Sites.
          Linked websites may have their own privacy notices or policies, which
          we strongly suggest you review if you visit any linked websites. To
          the extent any linked websites you visit are not part of this website,
          we are not responsible for their content, any use of the websites, or
          the privacy practices of any of those websites.
        </p>

        <h2>HOW WE PROTECT YOUR INFORMATION</h2>
        <p>
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information, and other data stored on
          our Sites. Sensitive and private data exchange between the Sites and
          its Users happens over a Secure Sockets Layer (SSL) secured
          communication channel and is encrypted and protected with digital
          signatures.<small>[LC10]</small>
        </p>

        <h2>HOW WE PROTECT PERSONAL INFORMATION</h2>
        <p>
          We maintain administrative, technical, and physical safeguards to
          protect against loss, misuse, unauthorized access, disclosure,
          alteration, or destruction of the information you provide when
          visiting or using our Sites.
        </p>

        <h2>RETENTION OF INFORMATION</h2>
        <p>
          Jiph retains all personal information for the duration of the relevant
          business relationship or, where required, in accordance with its
          information management policies and schedules, subject to applicable
          laws. When deleting personal information based on a request from the
          individual to whom the information relates, Jiph will make reasonable
          attempts to ensure that all instances of the information are deleted
          in their entirety.
        </p>

        <h2>HOW TO CONTACT US</h2>
        <p>
          If you have any questions or comments about this Notice, please
          contact: support@jiph.co
        </p>
        <p>
          If you would like to update your contact information or preferences,
          have your information removed from our mailing lists, or no longer
          want to receive marketing e-mails that we may send based on
          information collected via product registration cards or other sources,
          you may do so by:
        </p>
        <ul>
          <li>
            Adjusting the settings in the account you created through our Sites;
            or
          </li>
          <li>Unsubscribing via the links contained in emails from us.</li>
        </ul>

        <h2>CALIFORNIA CONSUMER PRIVACY STATEMENT</h2>
        <p class="note">Last Updated: OCTOBER 31ST, 2023</p>
        <p>
          This California Consumer Privacy Statement (“Statement”) supplements
          the Online Privacy Notice and applies solely to California consumers.
          This Statement does not apply to Jiph personnel or job applicants.
        </p>
        <p>
          This Statement uses certain terms that have the meaning given to them
          in the California Consumer Privacy Act of 2018 and its implementing
          regulations (the “CCPA”).
        </p>

        <h3>NOTICE OF COLLECTION AND USE OF PERSONAL INFORMATION</h3>
        <p>
          We may collect the following categories of personal information about
          you:
        </p>
        <ul>
          <li>
            <strong>Identifiers:</strong> identifiers such as a real name,
            postal address, unique personal identifier (such as a device
            identifier; cookies, beacons, pixel tags, mobile ad identifiers and
            similar technology; customer number, unique pseudonym, or user
            alias; telephone number and other forms of persistent or
            probabilistic identifiers), online identifier, internet protocol
            address, email address, account name, Social Security number,
            driver’s license number, passport number, and other similar
            identifiers.
          </li>
          <li>
            <strong
              >Additional Data Subject to Cal. Civ. Code § 1798.80:</strong
            >
            signature, physical characteristics or description, state
            identification card number, education, bank account number, credit
            card number, debit card number, and other financial information.
          </li>
          <li>
            <strong>Protected Classifications:</strong> characteristics of
            protected classifications under California or Federal law, such as
            race, color, national origin, age, sex, gender, marital status,
            citizenship status, and military and veteran status.
          </li>
          <li>
            <strong>Commercial Information:</strong> commercial information,
            including records of personal property, products or services
            purchased, obtained, or considered, and other purchasing or
            consuming histories or tendencies.
          </li>
          <li>
            <strong>Biometric Information</strong>
          </li>
          <li>
            <strong>Online Activity:</strong> internet and other electronic
            network activity information, including, but not limited to,
            browsing history, search history, and information regarding your
            interaction with websites, applications, or advertisements.
          </li>
          <li>Geolocation Data</li>
          <li>
            <strong>Sensory Information:</strong> audio, electronic, visual, and
            similar information.
          </li>
          <li>
            <strong>Employment Information:</strong> professional or
            employment-related information.
          </li>
          <li>
            <strong>Inferences:</strong> inferences drawn from any of the
            information identified above to create a profile about you
            reflecting your preferences, characteristics, psychological trends,
            predispositions, behavior, attitudes, intelligence, abilities, and
            aptitudes.
          </li>
        </ul>
        <p>
          We may use your personal information for the purposes described in our
          Jiph Online Privacy Policy and for the following business purposes
          specified in the CCPA:
        </p>
        <ul>
          <li>
            Performing services, including maintaining or servicing accounts,
            providing customer service, processing or fulfilling orders and
            transactions, verifying customer information, processing payments,
            providing advertising or marketing services, providing analytics
            services, or providing similar services;
          </li>
          <li>
            Auditing related to a current interaction with you and concurrent
            transactions, including, but not limited to, counting ad impressions
            to unique visitors, verifying positioning and quality of ad
            impressions, and auditing compliance;
          </li>
          <li>
            Short-term, transient use, including, but not limited to, the
            contextual customization of ads shown as part of the same
            interaction;
          </li>
          <li>
            Detecting security incidents, protecting against malicious,
            deceptive, fraudulent, or illegal activity, and prosecuting those
            responsible for that activity;
          </li>
          <li>
            Debugging to identify and repair errors that impair existing
            intended functionality;
          </li>
          <li>
            Undertaking internal research for technological development and
            demonstration;
          </li>
          <li>
            Undertaking activities to verify or maintain the quality or safety
            of a service or device that is owned, manufactured, manufactured
            for, or controlled by us, and to improve, upgrade, or enhance the
            service or device that is owned, manufactured, manufactured for, or
            controlled by us.
          </li>
        </ul>

        <h3>SALE OF PERSONAL INFORMATION</h3>
        <p>
          We do not sell personal information. Should this practice change, we
          will update this Notice accordingly and inform you of your rights to
          opt-out of the sale of your personal information.<small>[LC14]</small>
        </p>

        <h3>CALIFORNIA CONSUMER PRIVACY RIGHTS</h3>
        <p>
          You have certain choices regarding your personal information, as
          described below.
        </p>
        <p>
          <strong>Access:</strong> You have the right to request, twice in a
          12-month period, that we disclose to you the personal information we
          have collected, used, disclosed, and sold about you during the past 12
          months.
        </p>
        <p>
          <strong>Correction:</strong> You have the right to request that we
          correct inaccurate personal information we have about you.
        </p>
        <p>
          <strong>Deletion:</strong> You have the right to request that we
          delete certain personal information we have collected from you.
        </p>
        <p>
          <strong>Limitation:</strong> You have the right to limit the use and
          disclosure of sensitive personal information collected about you.
        </p>
        <p>
          <strong>Opt-Out:</strong> You have the right to opt out of the sale of
          your personal information. You also have the right to opt out of the
          sharing of your personal information for cross-context behavioral
          advertising.
        </p>
        <h3>Non-Discrimination</h3>
        <p>
          We will not discriminate against you in a manner prohibited by the
          CCPA because you exercise your CCPA rights.
        </p>
        <h3>Shine the Light Request</h3>
        <p>
          You also may have the right to request that we provide you with (1) a
          list of certain categories of personal information we have disclosed
          to third parties for their direct marketing purposes during the
          immediately preceding calendar year and, (2) the identity of those
          third parties.
        </p>
        <h3>How to Submit a Request</h3>
        <p>
          To submit an access or deletion request, please email us at
          support@jiph.co ] or submit a request through our webform. To submit a
          Shine the Light request, email us at support@jiph.co
          <small>[LC15]</small> If you designate an authorized agent to make an
          access or deletion request on your behalf using one of the channels
          described above, we may require you to provide the authorized agent
          written permission to do so and to verify your own identity directly
          with us (as described below). For questions or concerns about our
          privacy policies and practices, please contact us as described in the
          “How to Contact Us” section of our Privacy Policy.
        </p>
        <h3>Verifying Requests</h3>
        <p>
          To help protect your privacy and maintain security, we will take steps
          to verify your identity before granting you access to your personal
          information or complying with your request. We may require you to
          provide any of the following information: your name, date of birth,
          the last four digits of your Social Security number, the email and
          physical addresses associated with your Jiph account, one or more
          recent transactions, and the last four digits of one or more cards
          associated with your account.<small>[LC16]</small> If you have never
          had an account with us and you request access to or deletion of your
          personal information, there is no reasonable method by which we can
          verify your identity to the level of certainty required by the CCPA.
          The reason for this is that Jiph does not maintain information about
          non-accountholders in a way that is linked to named actual persons
          (and historically has not linked IP addresses, device identifiers or
          other information collected by automated means to named actual
          persons). In addition, if you ask us to provide you with specific
          pieces of personal information, we may require you to sign a
          declaration under penalty of perjury that you are the consumer whose
          personal information is the subject of the request.
        </p>
        <h3>Additional Information</h3>
        <p>
          If you choose to exercise any of your rights under the CCPA, you have
          the right to not receive discriminatory treatment by us. To the extent
          permitted by applicable law, we may charge a reasonable fee to comply
          with your request.
        </p>
        <!-- end of .content -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default {
  name: "PrivacyPolicy",

  metaInfo: {
    title: "Privacy Policy",
  },

  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.policy-container {
  /* background-color: #ddd; */
  height: 100%;
  padding: 32px;
}

h1 {
  margin-bottom: 15px;
}

h2 {
  margin-bottom: 10px;
}

.content {
  /* width: 90%; */
  margin: 0 auto 40px;
}

.content p {
  margin-bottom: 10px;
}
.content p.note {
  font-weight: 500;
  color: rgb(59, 130, 246);
}

.content ul {
  list-style-position: inside;
  margin: 30px auto;
  padding-left: 40px;
}

.content li {
  margin-bottom: 10px;
}

.content li.no-style {
  list-style-type: none;
}

small {
  font-size: 0.8rem;
  margin-left: 2px;
}
</style>
