<template>
  <div class="footer-container">
    <div class="landing-page-footer">
      <div class="landing-page-container23">
        <h1 class="landing-page-text51">
          <span class="landing-page-text52">
            Sending money has never been this
            <span v-html="raw6dy7"></span>
          </span>
          <span class="landing-page-text53">easy</span>
          <span class="landing-page-text54">
            ,
            <span v-html="rawfj3k"></span>
          </span>
          <span class="landing-page-text55">fast</span>
          <span class="landing-page-text56">
            , and
            <span v-html="rawip7m"></span>
          </span>
          <span class="landing-page-text57">cost-effective</span>
          <span class="landing-page-text58">!</span>
          <span><span v-html="rawxrig"></span></span>
          <br />
        </h1>
        <img
          alt="image"
          src="/svg/jiph-logo-white.svg"
          class="landing-page-image07"
        />
        <div class="landing-page-container24">
          <a
            href="https://www.facebook.com/jiphglobal"
            target="_blank"
            rel="noreferrer noopener"
            class="landing-page-link6"
          >
            <img
              alt="image"
              src="/svg/social-facebook.svg"
              class="landing-page-image08"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/69651247/admin/feed/posts/"
            target="_blank"
            rel="noreferrer noopener"
            class="landing-page-link7"
          >
            <img
              alt="image"
              src="/svg/social-linkedin.svg"
              class="landing-page-image09"
            />
          </a>
          <a
            href="https://www.instagram.com/jiphremittance/"
            target="_blank"
            rel="noreferrer noopener"
            class="landing-page-link8"
          >
            <img
              alt="image"
              src="/svg/social-instagram.svg"
              class="landing-page-image10"
            />
          </a>
          <img
            alt="image"
            src="/svg/social-tiktok.svg"
            class="landing-page-image11"
          />
          <img
            alt="image"
            src="/svg/social_twitter.svg"
            class="landing-page-image12"
          />
        </div>
        <div class="landing-page-container25">
          <img
            alt="image"
            src="/svg/download-apple.svg"
            class="landing-page-image13"
          />
          <img
            alt="image"
            src="/svg/download-google.svg"
            class="landing-page-image14"
          />
        </div>
      </div>
    </div>

    <footer class="landing-page-copyright">
      <div class="landing-page-container26">
        <div class="landing-page-container27">
          <span class="landing-page-text61">
            <span class="landing-page-text62">Copyright © 2023 Jiph.</span>
            <span><span v-html="rawgmzy"></span></span>
          </span>
          <a class="landing-page-text64" href="/terms-of-service">
            Terms of Service
          </a>
          <span class="landing-page-text65">|</span>
          <a
            class="landing-page-text66"
            href="https://www.zerohash.com/jiph/"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div class="landing-page-container28">
          <div class="landing-page-container29">
            <div class="landing-page-container30">
              <span class="landing-page-text67">
                <span class="landing-page-text68">Email:</span>
                <span><span v-html="rawvovy"></span></span>
                <span class="landing-page-text70"> contact@jiph.co</span>
                <span><span v-html="rawckfe"></span></span>
                <span><span v-html="rawaks7"></span></span>
              </span>
              <span class="landing-page-text73">
                <span><span v-html="raw3272"></span></span>
                <span class="landing-page-text75">|</span>
                <span><span v-html="rawytx6"></span></span>
                <span><span v-html="raw30k7"></span></span>
                <span><span v-html="rawjw9h"></span></span>
              </span>
            </div>
            <div class="landing-page-container31">
              <span class="landing-page-text79">
                <span class="landing-page-text80">Phone (US):</span>
                <span> 415-212-8340‬</span>
                <span><span v-html="rawifsx"></span></span>
                <span><span v-html="rawv58m"></span></span>
              </span>
              <span class="landing-page-text84">
                <span><span v-html="rawvwll"></span></span>
                <span class="landing-page-text86">|</span>
                <span><span v-html="rawq8pd"></span></span>
                u
                <span><span v-html="raw7pq0"></span></span>
                <span><span v-html="rawokbc"></span></span>
              </span>
              <span class="landing-page-text90">
                <span class="landing-page-text91">(PH):</span>
                <span> +1 415-212-8340</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer-container {
  width: 100%;
}

.landing-page-copyright {
  margin: auto;
}
</style>
