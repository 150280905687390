<template>
  <div class="terms-of-service-container">
    <div class="terms-of-service-top-container">
      <navbar pageName="terms-of-service"></navbar>
    </div>
    <div class="terms-of-service-wrapper">
      <p>
        <strong>
          <span style="font-size: 29px; line-height: 107%">
            JIPH&reg; User Service Agreement
          </span>
        </strong>
      </p>
      <ol style="list-style-type: decimal">
        <li><strong>JIPH&reg; User Service Introduction</strong></li>
      </ol>
      <p>&nbsp;</p>
      <p style="margin-left: 36pt; text-align: justify">
        This User Service Agreement (&ldquo;Agreement&rdquo; or &ldquo;Service
        Agreement&rdquo;) applies to &ldquo;Users&rdquo; enrolled in the service
        directly with JIPH&reg;.
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        In order to be a &ldquo;User&rdquo; of the JIPH&reg; network, this
        website, the JIPH&reg; Application, the &ldquo;Services&rdquo; provided
        in this &ldquo;Agreement&rdquo; and all related products, you must agree
        to these terms and enroll in the services made available by JIPH&reg; to
        you.&nbsp;
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        You also must consent to the JIPH&reg; E-sign Agreement and all
        JIPH&reg; Privacy Policies. &nbsp;By agreeing to this User Agreement,
        you are consenting and agreeing to the terms and conditions of the
        JIPH&reg; Privacy Policies, E-Sign Agreement and all other required
        documents (collectively the &ldquo;Agreement&rdquo;). &nbsp;The terms,
        of the Agreement(s) govern your use of the service and affect your legal
        rights, including requiring mandatory individual arbitration of
        disputes, please read them carefully.
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        In addition to agreeing to the Agreement(s) you also agree to comply
        with all requests for information and documents necessary to verify your
        identity and that of your financial institution. &nbsp;Failure to
        provide the necessary requested information will result in your
        ineligibility to use the JIPH&reg; Services including the website and
        application.
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        JIPH&reg;<sup>&nbsp;</sup>(referred to in this service agreement as
        &ldquo;JIPH&reg;,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or
        &ldquo;our&rdquo;) provides a service (the &ldquo;Service&rdquo;) that
        enables a convenient way to transfer money between you and others who
        are enrolled with a financial institution that partners with
        JIPH&reg;&nbsp;or with a JIPH<sup>&reg;</sup> affiliate, partner or
        vendor<span style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_1"
            href="#_msocom_1"
            language="JavaScript"
            name="_msoanchor_1"
            >[HR1]</a
          >&nbsp;</span
        >
        (each, a &ldquo;User&rdquo;) using aliases, such as email addresses or
        mobile phone numbers. We refer to financial institutions that have
        partnered or affiliates or vendors with us to offer the Service as
        &ldquo;Network Financial Institutions.&rdquo; &ldquo;User&rdquo; also
        refers to &ldquo;you&rdquo; and such pronouns as you identify.
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        This Agreement applies when Users send and receive money using the
        Service, with a bank account held at a financial institution that is
        within a &ldquo;Network Financial Institutions&rdquo; and if not then in
        accordance with that financial intuition&rsquo;s service agreement in
        the event of a conflict with this Agreement<span
          style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_2"
            href="#_msocom_2"
            language="JavaScript"
            name="_msoanchor_2"
            >[HR2]</a
          >&nbsp;</span
        >.&nbsp;
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        This service is not intended for your use to facilitate transfers to
        others that you have no relationship or do not trust. &nbsp;The
        intention is for this Services to be used to transfer funds between you
        and those You trust including family, friends and others You trust.
        &nbsp;The Service cannot be used for any purpose that is in violation of
        federal, state or local laws. &nbsp;
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        JIPH&reg; is not a bank and cannot hold deposits on your behalf<span
          style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_3"
            href="#_msocom_3"
            language="JavaScript"
            name="_msoanchor_3"
            >[HR3]</a
          >&nbsp;</span
        >. &nbsp;
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Service Description.</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              JIPH&reg; will facilitate a transfer of money, through its
              Financial Institution partners, or through its affiliates,
              partners and vendors, of User funds to another User upon request.
              &nbsp;All Users must be eligible, verified and have good funds
              available in an accepted financial institution to make
              transfers<span style="font-size: 11px"
                ><a
                  id="_anchor_4"
                  href="#_msocom_4"
                  language="JavaScript"
                  name="_msoanchor_4"
                  >[HR4]</a
                >&nbsp;</span
              >. &nbsp;
            </li>
            <li>
              JIPH&reg; is not a depository institution and does not hold your
              funds on deposit.
            </li>
          </ol>
        </li>
        <li>
          User Eligibility
          <ol style="list-style-type: upper-alpha">
            <li>
              To be a User, you must (a) enroll with a U.S. address, email
              address and U.S. mobile phone number, and (b) have a personal, not
              a business or commercial, bank account and debit card
              <span style="font-size: 11px"
                ><a
                  id="_anchor_5"
                  href="#_msocom_5"
                  language="JavaScript"
                  name="_msoanchor_5"
                  >[HR5]</a
                >&nbsp;</span
              >issued in conjunction with a U.S. domestic (not including U.S.
              territories) checking<span style="font-size: 11px"
                ><a
                  id="_anchor_6"
                  href="#_msocom_6"
                  language="JavaScript"
                  name="_msoanchor_6"
                  >[HR6]</a
                >&nbsp;</span
              >
              or savings account by a financial institution or a financial
              intuition within a User&rsquo;s country that is licensed and
              subject to those countries banking regulations and capable of
              international transactions. Your financial institution must permit
              electronic funds transfers to and from that account. You may not
              enroll with a prepaid debit card issued by a financial institution
              that is not a Network Financial Institution<span
                style="font-size: 11px"
                ><a
                  id="_anchor_7"
                  href="#_msocom_7"
                  language="JavaScript"
                  name="_msoanchor_7"
                  >[HR7]</a
                >&nbsp;</span
              >.&nbsp;
            </li>
            <li>
              When sending and receiving money with JIPH&reg;, if the recipient
              User is already enrolled, the money will be available as soon as
              possible. Some debit cards are not able to receive money in
              minutes. Those debit cards cannot be used with JIPH&reg;. More and
              more banks and credit unions are enabling their debit cards to
              have the ability to receive money in minutes, so if your debit
              card is not currently eligible, it may work in the future.
            </li>
            <li>
              When you enroll to use the Service, you agree to the terms and
              conditions of this Service Agreement.
            </li>
            <li>
              You represent that (a) you are at least 18 years of age, (b) you
              have the authority to authorize debits and credits to the enrolled
              bank account, and if applicable, (c) you are the authorized holder
              of the enrolled debit card or bank account.
            </li>
            <li>
              You agree that you will not use the Service to send money to
              anyone to whom you are obligated for tax payments, payments made
              pursuant to court orders (including court-ordered amounts for
              alimony or child support), fines, payments to loan sharks,
              gambling debts or payments otherwise prohibited by law, including
              without limitation, the prohibited uses described herein, and you
              agree that you will not use the Service to request money from
              anyone for any such payments. You agree that you will not
              authorize a third party to use the Service or share your
              credentials with a third party to use the Service on your behalf
              except in legally authorized situations such as legal guardianship
              or pursuant to a power of attorney. We reserve the right to
              suspend or terminate your use of the Service if we believe that
              you are using the Service in a manner that exposes JIPH&reg;
              and/or the Network Financial Institutions to liability,
              reputational harm or brand damage.
            </li>
            <li>
              When you enroll with JIPH&reg;, you may establish one profile per
              person. Each profile may be<span style="font-size: 11px"
                ><a
                  id="_anchor_8"
                  href="#_msocom_8"
                  language="JavaScript"
                  name="_msoanchor_8"
                  >[HR8]</a
                >&nbsp;</span
              >
              created with one aliases, such as your mobile phone number and/or
              email address; however, only one bank account and/or debit card
              may be linked to a profile. You may not establish more than one
              profile with the same mobile phone number or email address, and
              you may &nbsp;link more than one bank account or debit card to a
              particular mobile phone number or email address. When you enroll
              with JIPH&reg;, we will link the mobile number you provide to your
              new profile if that mobile number<span style="font-size: 11px"
                ><a
                  id="_anchor_9"
                  href="#_msocom_9"
                  language="JavaScript"
                  name="_msoanchor_9"
                  >[HR9]</a
                >&nbsp;</span
              >
              is not already linked to another JIPH&reg; profile. Other Users
              may send money to you or request money from you using that mobile
              number. If the email address that you provide when you enroll is
              not already linked to another profile that you may have enrolled
              with JIPH&reg; then we will also link that email address to your
              new profile so that others may send money to and request money
              from you using your email address.
            </li>
          </ol>
        </li>
        <li>
          <strong>JIPH&reg; User Enrollment (JIPH WALLETT)</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              <strong>User Enrollment</strong
              ><span style="font-size: 11px"
                ><a
                  id="_anchor_10"
                  href="#_msocom_10"
                  language="JavaScript"
                  name="_msoanchor_10"
                  >[HR10]</a
                >&nbsp;</span
              >: &nbsp;
              <ol style="list-style-type: lower-roman">
                <li>
                  In order to use the JIPH&reg; Services as a User you must
                  first enroll with JIPH&reg;. You are required to provide
                  JIPH&reg; with the following: &nbsp;
                </li>
                <li>
                  an email address that you regularly use and intend to use
                  regularly (i.e., no disposable email addresses),&nbsp;
                </li>
                <li>
                  a mobile phone<span style="font-size: 11px"
                    ><a
                      id="_anchor_11"
                      href="#_msocom_11"
                      language="JavaScript"
                      name="_msoanchor_11"
                      >[HR11]</a
                    >&nbsp;</span
                  >
                  number that you intend to use for an extended period of time
                  (i.e., no &ldquo;burner&rdquo; numbers), (You may not use a
                  landline phone or a VOIP number or a Google voice number<span
                    style="font-size: 11px"
                    ><a
                      id="_anchor_12"
                      href="#_msocom_12"
                      language="JavaScript"
                      name="_msoanchor_12"
                      >[HR12]</a
                    >&nbsp;</span
                  >)
                </li>
                <li>and your mailing address.&nbsp;</li>
                <li>
                  You must enroll for the Service on the JIPH&reg; mobile app
                  through your mobile device.
                </li>
                <li>
                  You are required to use an email address or mobile phone
                  number as your username and to create a unique password that
                  meets the security requirements described on our Site<span
                    style="font-size: 11px"
                    ><a
                      id="_anchor_13"
                      href="#_msocom_13"
                      language="JavaScript"
                      name="_msoanchor_13"
                      >[HR13]</a
                    >&nbsp;</span
                  >.
                </li>
                <li>
                  At enrollment, JIPH&reg; will collect information from you
                  necessary to verify your identity, which JIPH&reg; will do to
                  protect you, itself, the Network Financial Institutions, and
                  other financial institutions where you or the individual that
                  you are receiving money from or sending money to, hold an
                  account. &nbsp; The information will also be used as provided
                  in this Agreement including Section 6. JIPH&reg; reserves the
                  right to deny your enrollment or request additional
                  information from you if you do not provide this information,
                  if JIPH&reg; cannot verify your identity or your bank account
                  and/or debit card information, or if JIPH&reg; has concerns
                  about fraud or security.
                </li>
                <li>
                  JIPH&reg; also may request additional information about you
                  from time to time. JIPH&reg; may cancel or suspend your
                  enrollment or block a transaction if you do not provide this
                  information, if JIPH&reg; cannot verify your identity with the
                  information you provide, or if JIPH&reg; has concerns about
                  fraud or security.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="2" style="list-style-type: upper-alpha; margin-left: 62px">
        <li>
          <strong>Once enrolled:</strong>
          <ol style="list-style-type: lower-roman">
            <li>
              You may authorize a debit of your account to send money to a User
              that is enrolled as a customer of a Network Financial Institution
              (including in response to a request of that customer); however,
              there are a limited number of Network Financial Institutions that
              may not be able to accept money from certain financial
              institutions, your account, credit card or debit cards issued by
              financial institutions that are not Network Financial
              Institutions. YOU MAY NOT SEND MONEY TO A USER THAT IS ENROLLED AS
              A CUSTOMER OF A FINANCIAL INSTITUTION THAT IS NOT AN APPROVED
              NETWORK FINANCIAL INSTITUTION<span style="font-size: 11px"
                ><a
                  id="_anchor_14"
                  href="#_msocom_14"
                  language="JavaScript"
                  name="_msoanchor_14"
                  >[HR14]</a
                >&nbsp;</span
              >. &nbsp;(VIA NEXT PAY TO RECEIVE FUNDS)
              <ol style="list-style-type: decimal">
                <li>
                  YOU MAY ONLY SEND AND RECEIVE FUNDS FROM OR TO A USER FROM AN
                  APPROVED OR MEMBER NETWORK FINANCIAL INSTITUTION.<span
                    style="font-size: 11px"
                    ><a
                      id="_anchor_15"
                      href="#_msocom_15"
                      language="JavaScript"
                      name="_msoanchor_15"
                      >[HR15]</a
                    >&nbsp;</span
                  >
                </li>
              </ol>
            </li>
            <li>
              Once enrolled, WE NEED TO EXPLAIN WHAT HAPPENS instructions on
              how<span style="font-size: 11px"
                ><a
                  id="_anchor_16"
                  href="#_msocom_16"
                  language="JavaScript"
                  name="_msoanchor_16"
                  >[HR16]</a
                >&nbsp;</span
              >
              to enroll with JIPH&reg;.
            </li>
            <li>
              If at any time while you are enrolled, you do not send or receive
              money using the Service for a period of 18 consecutive months, we
              may contact you and/or take other steps to confirm that the mobile
              phone number or email address that you enrolled still belongs to
              you. If we are unable to confirm that you are the owner of the
              mobile phone number or email address, then you understand that we
              may cancel your enrollment and you will not be able to send or
              receive money with the Service until you enroll again.
            </li>
            <li>
              You may correct or change your profile information, including your
              bank information at any time. &nbsp;It is your responsibility to
              change your profile to avoid delays or issues with your
              transactions. &nbsp;JIPH&reg; is not responsible or liable for
              your failure to update your bank account information, email
              address, mobile number, profile or enrollment information.
              <ol style="list-style-type: undefined">
                <li>
                  You may cancel your use of the Service at any time by
                  notifying JIPH&reg; of your intent to cancel by contacting
                  customer service via email or telephone _______________. In
                  the event of cancelation, your profile will be rendered
                  inactive on you will no longer be authorized to utilize the
                  Service. Any transaction in process at the time of cancelation
                  will be completed. &nbsp;You will be eligible to reinstate
                  your profile however you may not be able to use the same
                  mobile number or email. &nbsp;You are hereby notified we will
                  retain your information in accordance with our Privacy Notice
                  and as required to comply with applicable law<span
                    style="font-size: 11px"
                    ><a
                      id="_anchor_17"
                      href="#_msocom_17"
                      language="JavaScript"
                      name="_msoanchor_17"
                      >[HR17]</a
                    >&nbsp;</span
                  >.
                </li>
                <li>
                  &nbsp;JIPH&reg; may terminate or suspend Service or this
                  Agreement and your use of the JIPH&reg; mobile app and
                  Service, in whole or in part, at any time for any reason
                  without cause and without prior notice. Any obligations or
                  liabilities incurred by you prior to the date of termination
                  shall survive the termination of this Service Agreement.
                  &nbsp;This includes your financial institutions failure to
                  participate or transfer funds on your behalf<span
                    style="font-size: 11px"
                    ><a
                      id="_anchor_18"
                      href="#_msocom_18"
                      language="JavaScript"
                      name="_msoanchor_18"
                      >[HR18]</a
                    >&nbsp;</span
                  >.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        The Service is intended for personal, not business or commercial use.
        You agree that you will<span style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_19"
            href="#_msocom_19"
            language="JavaScript"
            name="_msoanchor_19"
            >[HR19]</a
          >&nbsp;</span
        >
        not use the Service to send or receive payments in connection with your
        business or commercial enterprise. We reserve the right to decline your
        registration if we believe that you are enrolling to use the Service
        with your business account or to receive business or commercial
        payments. We further reserve the right to suspend or terminate your use
        of the Service if we believe that you are using the Service for business
        or commercial purposes, or for any of the uses identified directly
        below. Please note that eligible small businesses are permitted to
        enroll to use JIPH&reg; at certain Network Financial Institutions.
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        You agree that you will not use the Service to request, send or receive
        money related to any of the following:
      </p>
      <ol start="1" style="list-style-type: upper-alpha; margin-left: 62px">
        <li>
          Illegal activity, including illegal drugs and paraphernalia, sexually
          oriented activities, human trafficking, pornography, politically
          charged activities, insurrection, money laundering or activities that
          violate any national, state or local law;
        </li>
        <li>Ponzi, pyramid or fraud schemes;</li>
        <li>Illegal gambling, gaming, lotteries or sweepstakes;</li>
        <li>Court-ordered payments;</li>
        <li>
          Purchase or sale of securities or currency<span
            style="line-height: ; font-size: 8pt; line-height: "
            ><a
              id="_anchor_20"
              href="#_msocom_20"
              language="JavaScript"
              name="_msoanchor_20"
              >[HR20]</a
            >&nbsp;</span
          >;
        </li>
        <li>Counterfeit materials;</li>
        <li>
          Infringement on the copyright, patent, trademark, trade secret or
          other intellectual property rights;
        </li>
        <li>Terrorist funding;</li>
        <li>Money laundering; or</li>
        <li>Any other illegal activity or unlawful purpose.</li>
      </ol>
      <p style="margin-left: 72pt; text-align: justify">&nbsp;</p>
      <ol style="list-style-type: undefined">
        <li>
          <strong
            >Consent to Use and Disclose Personal Information (Including Account
            Information</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_21"
              href="#_msocom_21"
              language="JavaScript"
              name="_msoanchor_21"
              >[HR21]</a
            >&nbsp;</span
          ><strong>)</strong>
        </li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        By accepting this Service Agreement, you consent to JIPH&reg;&nbsp;using
        and disclosing your personal information (including bank account
        information) to JIPH&reg;, to our Network Financial Institutions or
        other third parties for the following purposes:
      </p>
      <ol start="1" style="list-style-type: upper-alpha; margin-left: 62px">
        <li>
          for internal operations including verifying User information,
          improvements, and internal diagnostics and research;
        </li>
        <li>
          to facilitate and complete transactions, including enabling our bank
          and credit union partners and Network Financial Institutions to
          complete money transfers between their customers, our customers and
          you;
        </li>
        <li>for accounting and account servicing;</li>
        <li>
          for Customer service and to provide Users a safe, efficient,
          economical experience;
        </li>
        <li>
          to prevent, detect, and protect against security incidents, fraud, and
          prohibited or illegal activities;
        </li>
        <li>
          to verify the existence of your financial account; to provide you with
          a safe, efficient, and customized experience;
        </li>
        <li>to enforce our terms and conditions;</li>
        <li>
          to research and resolve issues related to transactions you sent or
          received;
        </li>
        <li>to debug, identify and repair bugs or system issues;</li>
        <li>
          to notify you, by text or email, of a pending money transfer or a
          request for payment;
        </li>
        <li>
          to facilitate your enrollment and resolve issues relating to your
          enrollment;
        </li>
        <li>
          to verify your identity and verify changes to your personal
          information and other verification purposes;
        </li>
        <li>for analytics;</li>
        <li>
          to create and deliver marketing communications to you about
          JIPH&reg;<sup>&nbsp;</sup>and its partners;
        </li>
        <li>
          to create, develop, operate, deliver, maintain, and improve our
          products and services;
        </li>
        <li>for legal compliance;</li>
        <li>
          to respond to your requests and comply with your stated communication
          preferences;
        </li>
        <li>for data mining;</li>
        <li>to provide other services you request;</li>
        <li>
          to access your bank accounts, credit cards or other deposit accounts
          at your financial institution or ours to facilitate and complete
          transactions;
        </li>
        <li>for other uses as reasonably necessary; and</li>
        <li>as otherwise permitted by the terms of our Privacy Notice.</li>
      </ol>
      <p
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 72pt;
          text-align: justify;
          line-height: normal;
        "
      >
        &nbsp;
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        You agree that in addition to agreeing to access your financial
        accounts, credit cards and other accounts, if your financial institution
        becomes a Network Financial Institution, you agree JIPH&reg; has the
        right to share relevant information about you and your account with that
        financial institution so it may contact you with changes or actions that
        may need to occur (if any) for you to continue uninterrupted use of the
        Service.&nbsp;
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        <strong>Except as otherwise provided above, JIPH&reg;</strong>
        <strong>will neither disclose nor share any of your personal</strong
        ><span style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_22"
            href="#_msocom_22"
            language="JavaScript"
            name="_msoanchor_22"
            >[HR22]</a
          >&nbsp;</span
        ><strong
          >&nbsp;information with Network Financial Institutions, affiliates or
          any third party for purposes of marketing their products or services,
          or those of JIPH&reg;, to you.</strong
        >
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Privacy and Information Security</strong></li>
      </ol>
      <p style="margin-left: 72pt; text-align: justify">
        JIPH&reg; Privacy and Security policies are provided in the JIPH&reg;
        Privacy Notice attached hereto and incorporated heart<span
          style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_23"
            href="#_msocom_23"
            language="JavaScript"
            name="_msoanchor_23"
            >[HR23]</a
          >&nbsp;</span
        >
        for all purposes _____LINK_________. &nbsp;
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Sending Money</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_24"
              href="#_msocom_24"
              language="JavaScript"
              name="_msoanchor_24"
              >[HR24]</a
            >&nbsp;</span
          >
          <ol style="list-style-type: upper-alpha">
            <li>
              As a User, you may send money to another User at your initiation
              or in response to that User&rsquo;s request for money. You
              understand that use of this Service by you shall at all times be
              subject to (i) this Service Agreement, (ii) your express
              authorization for a Network Financial Institution to initiate a
              debit entry to your bank account, and (iii) the terms and
              conditions of your account agreement with your financial
              institution and the JIPH&reg; Privacy Notice. You understand that
              when you send the payment, you will have no<span
                style="font-size: 11px"
                ><a
                  id="_anchor_25"
                  href="#_msocom_25"
                  language="JavaScript"
                  name="_msoanchor_25"
                  >[HR25]</a
                >&nbsp;</span
              >
              ability to stop it. You may only cancel a payment if the person to
              whom you sent the money has not yet enrolled in the Service with
              the email address or mobile number to which you initiated the
              payment. If the person you sent money to has already enrolled with
              JIPH&reg; with the email address or mobile number to which you
              initiated the payment, the money is sent directly to their bank
              account or other partner (except as otherwise provided below) and
              may not be canceled or revoked. We therefore recommend that you
              use the Service to send money only to people you know and trust.
            </li>
            <li>
              In most cases, when you are sending money to another enrolled
              User, the transfer will occur in minutes; however, there are
              circumstances when the payment may take longer. For example, in
              order to protect you, JIPH&reg; and the Network Financial
              Institutions, we may need additional time to verify your identity
              or the identity<span style="font-size: 11px"
                ><a
                  id="_anchor_26"
                  href="#_msocom_26"
                  language="JavaScript"
                  name="_msoanchor_26"
                  >[HR26]</a
                >&nbsp;</span
              >
              of the person receiving the money. There may also be situations
              where funds were debited from your account, but do not reach the
              intended recipient for some other technical reason (e.g., failed
              payment, outage, etc.). In these circumstances, please allow up to
              48 hours for the funds to reach the intended recipient or to be
              returned.
            </li>
            <li>
              If you are sending money to a recipient that has not enrolled the
              email address or U.S. mobile number to which you initiate the
              payment with JIPH&reg;, either in the JIPH&reg; mobile app or with
              a Network Financial Institution, JIPH&reg; will send that intended
              recipient an invitation to enroll. If that recipient is also not a
              customer of a Network Financial Institution, or if the recipient
              does not accept the invitation to enroll, then the money will not
              be sent and it may take up to fifteen (15) banking days for the
              money to be returned to your account.
            </li>
            <li>
              You understand and acknowledge that a person to whom you are
              sending money and who is not enrolled as a User may fail to enroll
              with JIPH&reg;, or otherwise ignore the payment notification, and
              the transfer may not occur.
            </li>
            <li>
              The money may also be delayed or the transfer may be blocked by
              JIPH&reg; to prevent fraud or comply with regulatory requirements.
              If JIPH&reg; delays or blocks a transfer that you have initiated,
              JIPH&reg; will notify you in accordance with your notification
              preferences (i.e., email, text message, in-app notification). If
              you have chosen to receive in-app notifications through the
              JIPH&reg; mobile application, JIPH&reg; may send you both an
              in-app notification and an email to inform you that a payment that
              you initiated has been delayed or blocked.
            </li>
            <li>
              JIPH&reg; has no control over the actions of other Users, the
              Network Financial Institution or other financial institutions that
              could delay or prevent your money from being delivered to the
              intended User.
            </li>
          </ol>
        </li>
        <li>
          <strong>Receiving Money; Money Transfers</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_27"
              href="#_msocom_27"
              language="JavaScript"
              name="_msoanchor_27"
              >[HR27]</a
            >&nbsp;</span
          ><strong>;&nbsp;</strong>
        </li>
      </ol>
      <p
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 0cm;
          margin-left: 72pt;
          text-align: justify;
          text-indent: -18pt;
        "
      >
        A. &nbsp; &nbsp;Once a User initiates a transfer of money to your email
        address or mobile phone number<span
          style="font-size: 11px; line-height: 107%"
          ><a
            id="_anchor_28"
            href="#_msocom_28"
            language="JavaScript"
            name="_msoanchor_28"
            >[HR28]</a
          >&nbsp;</span
        >
        enrolled with the Service, you have no ability to stop the transfer. By
        using the Service, you agree and authorize our Network Financial
        Institutions to initiate credit entries to the bank account or debit
        card you have enrolled with, JIPH&reg;.
      </p>
      <p style="margin-left: 72pt; text-align: justify; text-indent: -18pt">
        B. &nbsp; &nbsp;Most transfers of money to you from other Users will
        occur within minutes. There may be other circumstances when the payment
        may take longer. For example, in order to protect you, JIPH&reg; and the
        Network Financial Institutions, JIPH&reg; may need additional time to
        verify your identity or the identity of the person sending the money. We
        may also delay or block the transfer to prevent fraud or to meet our
        regulatory obligations. There may also be situations where the payment
        may be delayed or not reach you due to a technical reason (e.g., failed
        payment, outage, etc.). In these circumstances, the payment may take up
        to forty-eight (48) hours (excluding weekends and holidays) or the
        sender may need to initiate a new transfer.
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Email, Telephone and Text Message Consent</strong></li>
      </ol>
      <p
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 12pt;
          margin-left: 36pt;
          text-align: justify;
        "
      >
        As a User you have represented to JIPH&reg; that you are the owner of
        the email address, the mobile phone number, and the name you enrolled
        under is yours and/or you have the authority to legal use for the
        purposes of this Agreement. &nbsp; You agree and consent to the receipt
        of emails or text messages from JIPH&reg;, Users that are sending you
        money or requesting money from you, and the Network Financial
        Institutions or their agents regarding the Service or related transfers
        between Network Financial Institutions or other financial institutions,
        partners and you. You consent and agree that JIPH&reg;, the Network
        Financial Institutions, its partners, vendors and their respective
        agents may use automatic telephone dialing systems in connection with
        calling or automated text messages sent to any mobile phone number you
        enroll. You consent and agree that JIPH&reg;, the Network Financial
        Institutions, its partners, vendors and their respective agents may
        email you in connection with your use of the JIPH&reg; Service, this
        website or the JIPH&reg; App for any purpose provided for in this
        Agreement. &nbsp;You further acknowledge, consent and agree:
      </p>
      <ol style="list-style-type: upper-alpha">
        <li>
          You are responsible for any fees or other charges that your wireless
          carrier may charge for any related data, text or other message
          services, including without limitation any message service as provided
          for in your wireless carrier agreement. &nbsp;You agree that
          JIPH&reg;, its affiliates, partners, vendors, Network Financial
          Institutions, and related entities are not responsible for any fees
          are charges for text messaging, emails or data. &nbsp;Please note you
          may receive text and email charges, including data charges, from your
          wireless carrier or provider(s). &nbsp;
        </li>
        <li>
          You will immediately notify us if any mobile phone number or email
          address you have enrolled is (i) surrendered by you, or (ii) changed
          by you.
        </li>
        <li>
          In the case of any messages that you may send through JIPH&reg; or
          that JIPH&reg; may send on your behalf to an email address or mobile
          phone number, you hereby represent that you have obtained the
          recipients consent to receive such texts, emails or automated text
          messages.
        </li>
        <li>
          You agree and consent to JIPH&reg; including your name and other
          private information in any suck communication to your recipient or
          financial institutions (including Network Financial Institution).
          &nbsp;
        </li>
        <li>
          You consent, agree and authorize your wireless carrier to use or
          disclose information about your account and your wireless device, as
          available, to JIPH&reg; or its service provider for the duration of
          your enrolment and use of JIPH&reg; Services. &nbsp;
        </li>
        <li>
          You agree JIPH&reg; is not responsible for your wireless carrier or
          email providers delay or failure to deliver any message sent to or
          from JIPH&reg;, including messages that you may send through JIPH&reg;
          or that JIPH&reg; may send on your behalf or for the transfer of funds
          through the Service.
        </li>
        <li>
          To cancel text messaging from JIPH&reg;, email STOP or by contacting
          customer support at ________________. &nbsp;You<span
            style="font-size: 11px"
            ><a
              id="_anchor_29"
              href="#_msocom_29"
              language="JavaScript"
              name="_msoanchor_29"
              >[HR29]</a
            >&nbsp;</span
          >
          however consent to JIPH&reg; texting or emailing you confirmation of
          your desire to cancel text messaging. &nbsp;
        </li>
        <li>
          You consent, agree and authorize your wireless carrier to use or
          disclose information about your account and your wireless device, as
          available, to JIPH&reg; or its service provider for the duration of
          your enrolment and use of JIPH&reg; Services. &nbsp;
        </li>
      </ol>
      <ol style="list-style-type: undefined">
        <li><strong>Wireless Operator Data</strong></li>
      </ol>
      <p style="margin-bottom: 12pt; text-align: justify">
        You authorize your wireless carrier to use or disclose information about
        your account and your wireless device, if available, to JIPH&reg; or its
        service provider for the duration of your business relationship, solely
        to help them identify you or your wireless device and to prevent fraud.
        See our Privacy Policy for how we treat your data.
      </p>
      <ol class="decimal_type" style="list-style-type: undefined">
        <li>
          <strong>Sending Money; Debits by Network Financial&nbsp;</strong
          ><strong>Institutions</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_30"
              href="#_msocom_30"
              language="JavaScript"
              name="_msoanchor_30"
              >[HR30]</a
            >&nbsp;</span
          >
          <ol style="list-style-type: upper-alpha">
            <li>
              You may only send money to another illegible User that has
              established a JIPH&reg; Wallett. at your initiation or in response
              to that User&rsquo;s request for money. You understand that use of
              this Service by you shall at all times be subject to (i) this
              Service Agreement, (ii) your express authorization for a Network
              Financial Institution to initiate a debit entry to your bank
              account, and (iii) the terms and conditions of your account
              agreement with your financial institution, and (iv) JIPH&reg;
              Privacy Policy. You understand that when you send the payment, you
              will have no ability to stop it. You may only cancel a payment if
              the person to whom you sent the money has not yet enrolled in the
              Service<span style="font-size: 11px"
                ><a
                  id="_anchor_31"
                  href="#_msocom_31"
                  language="JavaScript"
                  name="_msoanchor_31"
                  >[HR31]</a
                >&nbsp;</span
              >
              with the email address or mobile number to which you initiated the
              payment. If the person you sent money to has already enrolled with
              JIPH&reg; with the email address or mobile number to which you
              initiated the payment, the money is sent directly to their bank
              account (except as otherwise provided below) and may not be
              canceled or revoked. We therefore recommend that you use the
              Service to send money only to people you know and trust.
            </li>
            <li>
              We endeavor to transfer funds between enrolled Users within as
              soon as possible in most instances; however, there are
              circumstances when the payment may take longer. Delays can be
              caused by many issues including verification of your identity,
              your Network Financial Institution information, account balances,
              or verification of the person you are sending funds. There may
              also be situations where funds were debited from your account, but
              do not reach the intended recipient for some other technical
              reason (e.g., failed payment, outage, network interruption, cell
              data issues, etc.). In these circumstances, please allow up to
              seventy-two (72) hours for the funds to reach the intended
              recipient or to be returned.
            </li>
            <li>
              The money may also be delayed or the transfer may be blocked by
              JIPH&reg; to prevent fraud, to comply with regulatory requirements
              or to ensure compliance with this Agreement. If JIPH&reg; delays
              or blocks a transfer that you have initiated, JIPH&reg; will
              notify you in accordance with your notification preferences (i.e.,
              email, text message, in-app notification<span
                style="font-size: 11px"
                ><a
                  id="_anchor_32"
                  href="#_msocom_32"
                  language="JavaScript"
                  name="_msoanchor_32"
                  >[HR32]</a
                >&nbsp;</span
              >). If you have chosen to receive in-app notifications through the
              JIPH&reg; mobile application, JIPH&reg; may send you both an
              in-app notification and an email to inform you that a payment that
              you initiated has been delayed or blocked.
            </li>
            <li>
              JIPH&reg; has no control over the actions of other Users, the
              Network Financial Institution, other financial institutions,
              partners, vendors or others that could delay or prevent your money
              from being delivered to the intended User.
            </li>
          </ol>
        </li>
        <li>
          <strong>Liability for Sending and Receiving Money</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              Neither JIPH&reg; nor its affiliates, partners, vendors or the
              Network Financial Institutions shall have any liability to you for
              any transfers of money, including without limitation, (i) any
              failure, through no fault of JIPH&reg;, a partner, vendor or the
              Network Financial Institutions, to complete a transaction in the
              correct amount, or (ii) any related losses or damages. We
              recommend that you send money only to friends, family and others
              that you know and trust.
            </li>
            <li>
              Neither JIPH&reg;, its affiliates, partners, vendors nor the
              Network Financial Institutions shall be liable for any typos or
              keystroke errors that you may make when using the Service.
              <strong
                >YOU UNDERSTAND AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR
                ENTERING THE CORRECT MOBILE PHONE NUMBER OR EMAIL ADDRESS FOR
                THE PERSON THAT YOU ARE SENDING MONEY TO OR REQUESTING MONEY
                FROM, AND THAT YOU, NOT JIPH&reg; ITS AFFILIATES, PARTNERS,
                VENDORS OR THE NETWORK FINANCIAL INSTITUTIONS, ARE RESPONSIBLE
                FOR ANY AMOUNTS THAT ARE TRANSFERRED TO THE INCORRECT PERSON AS
                A RESULT OF YOU ENTERING THE INCORRECT MOBILE NUMBER OR EMAIL
                ADDRESS.</strong
              >
            </li>
            <li>
              You acknowledge and agrees that neither JIPH&reg; its affiliates,
              partners, vendors nor the Network Financial Institutions shall
              have any liability for your failure to comply with the terms and
              conditions of your account agreement with your financial
              institution.
            </li>
            <li>
              <strong
                >THE SERVICE IS ONLY INTENDED FOR SENDING MONEY TO FAMILY,
                FRIENDS AND OTHERS</strong
              ><span style="font-size: 11px"
                ><a
                  id="_anchor_33"
                  href="#_msocom_33"
                  language="JavaScript"
                  name="_msoanchor_33"
                  >[HR33]</a
                >&nbsp;</span
              ><strong
                >&nbsp;YOU TRUST. YOU SHOULD NOT USE THE JIPH&reg; SERVICE TO
                SEND MONEY TO PERSONS OR ENTITIES WITH WHOM YOU ARE NOT FAMILIAR
                OR YOU DO NOT TRUST. &nbsp;JIPH&reg; DOES NOT OFFER A PROTECTION
                PROGRAM FOR AUTHORIZED PAYMENTS MADE WITH THE SERVICE.
                &nbsp;JIPH&reg; IS NOT LIABLE FOR GOODS AND SERVICES PAID FOR
                THROUGH THE SERVICES THAT YOU DO NOT RECEIVE, THAT ARE DAMAGED,
                THAT YOU DID NOT EXPECT OR THAT YOU ARE
                DISSATISFIED.&nbsp;</strong
              >
            </li>
          </ol>
        </li>
        <li>
          <strong>Limitations on Transfers: Send and Receive Limits</strong>
        </li>
      </ol>
      <p style="margin-bottom: 12pt; text-align: justify">
        When using the Service, you may send no more than $1,000.00 USD per
        transaction<span style="font-size: 11px"
          ><a
            id="_anchor_34"
            href="#_msocom_34"
            language="JavaScript"
            name="_msoanchor_34"
            >[HR34]</a
          >&nbsp;</span
        >
        and no more than $10,000USD per month with no limit on the number of
        transactions. &nbsp;
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Requesting Money</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_35"
              href="#_msocom_35"
              language="JavaScript"
              name="_msoanchor_35"
              >[HR35]</a
            >&nbsp;</span
          ><span style="font-size: 11px"
            ><a
              id="_anchor_36"
              href="#_msocom_36"
              language="JavaScript"
              name="_msoanchor_36"
              >[HR36]</a
            >&nbsp;</span
          >
          <ol style="list-style-type: upper-alpha">
            <li>
              You may request money from another User. You understand and
              acknowledge that Users to whom you send payment requests may
              reject or ignore your request. JIPH&reg; does not guarantee that
              you will receive money from other Users by sending a payment
              request, or that you will receive the amount that you request.
              Neither JIPH&reg; nor the Network Financial Institutions accept
              responsibility if the other User rejects or ignores your request
              or sends you an amount that is less than you request. If a User
              ignores your request, JIPH&reg; may decide, in its sole
              discretion, that it will not send a reminder or repeat request to
              that User.
            </li>
            <li>
              By accepting this Service Agreement, you agree that you are not
              engaging in the business of debt collection by attempting to use
              the Service to request money for the payment or collection of an
              overdue or delinquent debt; to request money that is owed to
              another person; or to collect any amounts that are owed pursuant
              to a court order. You agree to indemnify, defend and hold harmless
              JIPH&reg;, its owners, directors, officers, employees, attorneys,
              representatives, agents and the Network Financial Institutions
              from and against all claims, losses, expenses, damages and costs
              (including, but not limited to, direct, incidental, consequential,
              exemplary and indirect damages), and reasonable attorneys&rsquo;
              fees, resulting from or arising out of any request for money that
              you send that is related to overdue or delinquent amounts.
            </li>
            <li>
              You agree to receive money requests from others and to only send
              requests for legitimate and lawful purposes. Requests for money
              are solely between the sender and recipient and may not be
              reviewed or verified by JIPH&reg; or the Network Financial
              Institutions except as otherwise allowed by this Service
              Agreement. Neither JIPH&reg; nor the Network Financial
              Institutions assume responsibility for the accuracy or legality of
              such requests and do not act as a debt collector on your behalf or
              on behalf of the sender of a request for money.
            </li>
            <li>
              We reserve the right, but assume no obligation, to terminate your
              ability to send requests for money in general, or to specific
              recipients, if we deem such requests to be potentially unlawful,
              abusive, offensive or unwelcome by the recipient.
            </li>
          </ol>
        </li>
        <li>
          <strong>Transaction Errors</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              If you have a question about a transfer that you received or
              expected to receive, then you should contact the sender first and
              attempt to resolve the issue. ALL QUESTIONS ABOUT TRANSFERS THAT
              YOU INITIATED USING THE SERVICE SHOULD FIRST BE DIRECTED TO
              JIPH&reg; AND THEN TO THE FINANCIAL INSTITUTION WHERE YOU HAVE
              YOUR ACCOUNT.
            </li>
            <li>
              We will not send you a periodic statement listing transfers
              facilitated through JIPH&reg;. Those transfers will appear only on
              the statement issued by your financial institution<span
                style="font-size: 11px"
                ><a
                  id="_anchor_37"
                  href="#_msocom_37"
                  language="JavaScript"
                  name="_msoanchor_37"
                  >[HR37]</a
                >&nbsp;</span
              >. PLEASE RETAIN THE RECORDS FROM YOUR USE OF THE SERVICE AND
              CHECK THEM AGAINST THE STATEMENT YOU RECEIVE FROM YOUR FINANCIAL
              INSTITUTION.
            </li>
            <li>
              In case of errors or questions about your transactions facilitated
              through JIPH&reg;, please contact us by telephone __________ or by
              e-mail at customerservice@JIPH&reg;.us as promptly as you can. We
              must hear from you no later than sixty (60) days after<span
                style="font-size: 11px"
                ><a
                  id="_anchor_38"
                  href="#_msocom_38"
                  language="JavaScript"
                  name="_msoanchor_38"
                  >[HR38]</a
                >&nbsp;</span
              >
              you received the first statement or receipt upon which the problem
              or error appeared. You understand that JIPH&reg; hosts a
              centralized database of information to facilitate payments, but
              neither initiates nor processes electronic payments or transfers
              directly<span style="font-size: 11px"
                ><a
                  id="_anchor_39"
                  href="#_msocom_39"
                  language="JavaScript"
                  name="_msoanchor_39"
                  >[HR39]</a
                >&nbsp;</span
              >.
            </li>
            <li>
              In the event of an error, please identify yourself by name and
              your mobile number. &nbsp;Please describe in detail the error or
              concern you have regarding a transfer or receipt. &nbsp;Tell us
              the amount of the transfer and the date of the possible error.
              &nbsp;
            </li>
            <li>
              We will need up to ten (10) US business days or more to respond to
              your inquiry.
            </li>
            <li>
              JIPH&reg; facilitates the transfer of funds through the Network of
              Financial Institution&rsquo;s, partners and vendors. &nbsp;It
              maintains database of information for the facilitation of money
              transfers. &nbsp;JIPH&reg; neither transfers, moves nor initiates
              the transfer or movement of money<span style="font-size: 11px"
                ><a
                  id="_anchor_40"
                  href="#_msocom_40"
                  language="JavaScript"
                  name="_msoanchor_40"
                  >[HR40]</a
                >&nbsp;</span
              >. JIPH&reg; will determine whether there is an error in the
              database or whether an error occurred in the delivery of a Message
              related to the transaction and will correct any error promptly. If
              JIPH&reg; determines that no error occurred in the database or the
              Message, then JIPH&reg; will work with the Network Financial
              Institution that initiated the transfer to assist with the
              investigation of your claim. We will determine whether an error
              occurred within ten (10) US business days after we hear from you
              and will correct any error promptly.
            </li>
            <li>
              JIPH&reg;<span style="font-size: 11px"
                ><a
                  id="_anchor_41"
                  href="#_msocom_41"
                  language="JavaScript"
                  name="_msoanchor_41"
                  >[HR41]</a
                >&nbsp;</span
              >&reg; will tell you the results within five (5) US business days
              after completing our investigation. If we decide that there was no
              error, we<span style="font-size: 11px"
                ><a
                  id="_anchor_42"
                  href="#_msocom_42"
                  language="JavaScript"
                  name="_msoanchor_42"
                  >[HR42]</a
                >&nbsp;</span
              >
              will send you a written explanation.&nbsp;
            </li>
            <li>
              For the purpose of this Service Agreement, &ldquo;business
              days&rdquo; means US Monday through Friday, excluding federal
              banking holidays.
            </li>
          </ol>
        </li>
      </ol>
      <p style="margin-left: 108pt; text-align: justify">&nbsp;</p>
      <ol style="list-style-type: undefined">
        <li><strong>User Liability for Unauthorized Transfers</strong></li>
      </ol>
      <p style="margin-bottom: 12pt; text-align: justify">
        In the event that you believe there has been an unauthorized transfer,
        please immediately call JIPH&reg; at ________________ or write to
        customerservice@JIPH&reg;.us. This includes the following:
      </p>
      <ol style="list-style-type: upper-alpha">
        <li>Your JIPH&reg; password has been stolen;</li>
        <li>
          Your mobile device with your JIPH&reg; mobile app and password has
          been lost or stolen.&nbsp;
        </li>
        <li>
          You believe that a transfer of money has been made from your enrolled
          bank account using the Service without your permission.
        </li>
        <li>
          You believe an unauthorized person has discovered your JIPH&reg;
          password or accessed the JIPH&reg; mobile app on your mobile device
          and may transfer money from your enrolled bank account using the
          JIPH&reg; mobile app without your permission.
        </li>
        <li>
          You need information to determine if an unauthorized transfer
          occurred.
        </li>
        <li>
          You believe the JIPH&reg; app or Services has been used to make
          unauthorized transactions to or from your bank account, or if your
          statements reflect a transaction error utilizing the JIPH&reg; Service
          <strong>please contact your financial institution immediately</strong
          >.
        </li>
        <li>
          By acting immediately, you will help to correct the error and limit
          your liability and potential loss. &nbsp;Please consult your financial
          institution for your liability limits.
        </li>
        <li>
          Provided you report the loss within four (4) business days of the loss
          of your password or mobile device, your liability will be limited to
          fifty US dollars ($50.00USD) or the amount of an authorized transfer
          prior to the report, not to exceed one hundred US dollars
          ($100.00USD).&nbsp;
        </li>
        <li>
          In the event you fail to report the loss within the time provided
          above, your liability will not exceed &nbsp;the lesser of &nbsp;1)
          $500, or 2) the sum of either $50 or<span style="font-size: 11px"
            ><a
              id="_anchor_43"
              href="#_msocom_43"
              language="JavaScript"
              name="_msoanchor_43"
              >[HR43]</a
            >&nbsp;</span
          >
          the amount of unauthorized transfers that occur before you reported
          the loss of your password or mobile device (whichever is less) , plus
          the amount of unauthorized transfers that occur after the four (4)
          business days and before you reported the loss of your password or
          mobile device, if those unauthorized transfers would not have occurred
          if you had reported the loss<span style="font-size: 11px"
            ><a
              id="_anchor_44"
              href="#_msocom_44"
              language="JavaScript"
              name="_msoanchor_44"
              >[HR44]</a
            >&nbsp;</span
          >.
        </li>
      </ol>
      <ol style="list-style-type: undefined">
        <li><strong>Liability for Failure to Complete Transfers</strong></li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        JIPH&reg; its affiliates, partners, vendors and the Network Financial
        Institutions&nbsp;have no liability for your losses or damages in the
        event, through no fault of JIPH&reg;<sup>&nbsp;</sup>the following
        occur:
      </p>
      <ol style="list-style-type: upper-alpha">
        <li>
          The User sending funds fails to properly enter the receiver&rsquo;s
          information<span style="font-size: 11px"
            ><a
              id="_anchor_45"
              href="#_msocom_45"
              language="JavaScript"
              name="_msoanchor_45"
              >[HR45]</a
            >&nbsp;</span
          >.
        </li>
        <li>The Sender User fails to complete the transfer to your account.</li>
        <li>
          The Sender&rsquo;s account does not contain sufficient funds to make
          the transfer.&nbsp;
        </li>
        <li>
          The Receiver fails to receive the information related to the transfer
          including payment information.&nbsp;
        </li>
        <li>
          JIPH&reg; its affiliates, partners, vendors and the Network Financial
          Institutions services are interrupted and are not working.&nbsp;
        </li>
        <li>The User knows the Services are down or not functioning.</li>
        <li>
          In the event the vendor or financial institutions delay processing or
          posting a transfer or receipt of money.&nbsp;
        </li>
        <li>
          In the event of circumstances beyond the control of JIPH&reg; its
          affiliates, partners, vendors and the Network Financial Institutions
          including but not limited to, acts of God, insurrection, strike, war,
          rebellion, weather, pandemic, cyber-attack or any other catastrophic
          event.&nbsp;
        </li>
        <li>
          Any other event out of the control of JIPH&reg;&nbsp;its affiliates,
          partners, vendors and the Network Financial Institutions and any third
          party related in any way to the transaction or facilitation of the
          transfer of money.
        </li>
      </ol>
      <ol style="list-style-type: undefined">
        <li>
          <strong>User Transfer Fees</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_46"
              href="#_msocom_46"
              language="JavaScript"
              name="_msoanchor_46"
              >[HR46]</a
            >&nbsp;</span
          >
        </li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        JIPH&reg;&nbsp;charges the following fees to the User:
      </p>
      <ol style="list-style-type: upper-alpha">
        <li>
          Each transfer utilizing the Services, a fee of One US Dollar
          ($1.00USD) per transfer<span style="font-size: 11px"
            ><a
              id="_anchor_47"
              href="#_msocom_47"
              language="JavaScript"
              name="_msoanchor_47"
              >[HR47]</a
            >&nbsp;</span
          >
          will be charged the sending and receiving User each
          (&ldquo;Fee&rdquo;).
        </li>
        <li>
          For amounts exceeding one-hundred US Dollars ($100.00USD) a fee of one
          US Dollar ($1.00) will be charged to the sender and receiver for each
          fifty US Dolars ($50.00USD) or
          <strong>part there of thereafter</strong> (&ldquo;Extra Fee&rdquo;).
          &nbsp;For example, a transfer of $175.00USD the Fee will be added and
          then the Extra Fee for a total of $3.00 to the sender and $3.00 to the
          recipients ($1.00Fee for the first $100, $1.00 extra fee for the next
          $50 and $1.00 for the next $25.00.
        </li>
      </ol>
      <ol style="list-style-type: undefined">
        <li><strong>Additional Costs</strong></li>
      </ol>
      <p
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 12pt;
          margin-left: 54pt;
          text-align: justify;
        "
      >
        <strong
          >In the event of money needs to be exchanged for different currencies
          utilizing FX or some other service, JIPH may charge USER a percentage
          to the exchange rate. &nbsp;The amount to be charged will vary based
          upon currency exchange rate fluctuations and internal
          policies.</strong
        >
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Amendments to User Agreement</strong></li>
      </ol>
      <p
        style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 12pt;
          margin-left: 36pt;
          text-align: justify;
        "
      >
        Subject to applicable law, JIPH&reg; may amend or change this Service
        Agreement (including any applicable fees and service charges) from time
        to time, in its sole discretion, by posting the updated terms on the
        JIPH&reg; mobile app and at JIPH&reg; (each referred to individually or
        collectively as, the &ldquo;Site&rdquo;). &nbsp; We request that you
        periodically review this Agreement. If you find the Service Agreement
        unacceptable to you at any time, promptly cancel your profile and
        enrollment with JIPH&reg; and immediately discontinue your use of the
        Service. Your continued access of the JIPH&reg; Site or use of the
        Service after any amendment, modification or change to the terms and
        conditions of this Service Agreement shall constitute your agreement and
        acceptance to such amendment, modification or change.
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Disclaimer of Warranties</strong></li>
      </ol>
      <p style="margin-bottom: 12pt; text-align: justify">
        EXCEPT AS OTHERWISE PROVIDED HEREIN, AND SUBJECT TO APPLICABLE LAW,
        JIPH&reg; MAKES NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR
        ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE SERVICE. JIPH&reg; EXPRESSLY
        DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR
        OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT, WITH REGARD TO THE SERVICE DESCRIBED OR PROVIDED.
        JIPH&reg; DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED,
        TIMELY, INVULNERABLE TO CYBER-ATTACK, PANDEMIC, INSURRECTION, WAR OR
        ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED. THE SERVICE IS PROVIDED
        ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS.
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Limitation of Liability</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              EXCEPT AS OTHERWISE PROVIDED HEREIN AND SUBJECT TO APPLICABLE LAW,
              IN NO EVENT WILL JIPH&reg;, ITS OWNERS, DIRECTORS, OFFICERS,
              AGENTS, ADVISORS, PARTNERS OR NETWORK FINANCIAL INSTITUTIONS BE
              LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING, BUT NOT LIMITED TO
              ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR OTHER
              INDIRECT DAMAGES ARISING OUT OF (I) ANY TRANSACTION CONDUCTED
              THROUGH OR FACILITATED BY THE SERVICE; (II) ANY CLAIM ATTRIBUTABLE
              TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE SERVICE
              DESCRIBED OR PROVIDED, (III) UNAUTHORIZED ACCESS TO OR ALTERATION
              OF YOUR TRANSMISSIONS OR DATA, OR (IV) ANY OTHER MATTER RELATING
              TO THE SERVICE DESCRIBED OR PROVIDED, EVEN IF JIPH&reg; HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOU ARE
              DISSATISFIED WITH THE SERVICE OR WITH THE TERMS OF THIS SERVICE
              AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
              THE SERVICE.
            </li>
            <li>
              IN THOSE COUNTRIES OR STATES WHERE THE EXCLUSION OR LIMITATION OF
              LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES MAY NOT APPLY,
              ANY LIABILITY OF JIPH&reg;, ITS OWNERS, DIRECTORS, OFFICERS,
              AGENTS, ADVISORS, PARTNERS OR NETWORK FINANCIAL INSTITUTIONS IN
              THOSE COUNTRIES OR STATES IS LIMITED AND WARRANTIES ARE EXCLUDED
              TO THE GREATEST EXTENT PERMITTED BY LAW, BUT SHALL, IN NO EVENT,
              EXCEED ONE HUNDRED DOLLARS ($100.00).
            </li>
          </ol>
        </li>
        <li><strong>Indemnification</strong></li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        You acknowledge and agree that you are personally responsible for your
        conduct while using the JIPH&reg; Service, and except as otherwise
        provided in this Service Agreement, you also agree to indemnify, defend
        and hold harmless JIPH&reg;<span
          style="font-size: 16px; line-height: 107%"
          >, its owners, directors, officers, agents, attorneys, advisors,
          partners,
          <ins cite="mailto:Hal%20Reiland" datetime="2023-11-06T18:59"
            >vendors&nbsp;</ins
          >or&nbsp;</span
        >Network Financial Institutions from and against all claims, losses,
        expenses, damages and costs (including, but not limited to, direct,
        incidental, consequential, exemplary and indirect damages), and
        reasonable attorneys&rsquo; fees, resulting from or arising out of your
        use, misuse, or inability to use the Service, or any violation by you of
        the terms of this Service Agreement.
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Arbitration Agreement</strong>
          <ol style="list-style-type: upper-alpha">
            <li>
              THE PARTIES AGREE THAT ANY AND ALL CLAIMS OR DISPUTES BETWEEN US
              RELATING IN ANY WAY TO THESE TERMS, OUR PRIVACY NOTICE, OR THE
              JIPH&reg; SERVICE MUST BE RESOLVED FIRST THROUGH MEDIATION AND IF
              NOT RESOLVED THROUGH MEDIATION BY THEN BY BINDING ARBITRATION.
              &nbsp;NO CLAIMS MAYBE MADE IN A COURT OF LAW. &nbsp;
            </li>
            <li>
              The parties agree to mediate, in good faith, any claim arising
              hereunder and to refrain from pursuing arbitration hereunder until
              the parties have met with a mediator. The parties agree to select
              and mediate any claim or controversy within sixty (60) days of the
              date the claim or controversy accrues or first arises. The
              mediator shall be selected by JIPH&reg; utilizing a mediator
              licensed to practice law with commercial law experience, from the
              American Arbitration Association or JAMS. &nbsp;You agree that
              your failure to timely mediate the dispute relieves JIPH&reg;<span
                style="font-size: 16px"
                >, its owners, Directors, Officers, Agents, Attorneys, Advisors,
                Partners, or&nbsp;</span
              >Network Financial Institutions from and against all claims that
              could be brought in Arbitration or court if available.
            </li>
            <li>
              The parties acknowledge and agree that after failed mediation, any
              claim or controversy arising out of or relating to these terms,
              our Privacy Notice or the JIPH&reg; SERVICE, or any other dispute
              arising out of or relating to the parties relationship, shall be
              settled by final and binding arbitration in the San Francisco Bay
              Area, State of California, United States of America, in accordance
              with the Commercial Arbitration Rules of the American Arbitration
              Association or JAMS in effect on the date the claim or controversy
              arises. The parties further acknowledge and agree that either
              party must request arbitration of any claim or controversy within
              one hundred twenty (120) days of the date the claim or controversy
              accrues or first arises by giving written notice of the
              party&rsquo;s request for arbitration by certified U.S. mail or
              personal delivery. Notice shall be effective upon delivery or
              mailing. Failure to give notice of any claim or controversy within
              one hundred twenty (120) days shall constitute a waiver of the
              claim or controversy. All claims or controversies subject to
              arbitration shall be submitted to arbitration within one hundred
              eighty (180) days from the date the written notice of a request
              for arbitration is effective. All claims or controversies shall be
              resolved by a single arbitrator who is licensed to practice law in
              the State of California and who is experienced in the arbitration
              of commercial disputes. The arbitrator shall be selected in
              accordance with the Commercial Arbitration Rules of the American
              Arbitration Association or JAMS in effect at the time the claim or
              controversy arises. Either party may request that the arbitration
              proceeding be stenographically or otherwise recorded by a
              Certified Shorthand Reporter. The arbitrator shall issue a written
              decision with respect to all claims or controversies within thirty
              (30) days from the date the claims or controversies are submitted
              to arbitration.&nbsp;
            </li>
          </ol>
        </li>
        <li><strong>Class Action Waiver</strong></li>
      </ol>
      <p style="margin-left: 36pt; text-align: justify">
        By accepting this Service Agreement and the JIPH&reg; Services, you
        agree that all claims and disputes will be resolved individually and not
        as part of a Class Action lawsuit &nbsp; You agree to waive your ability
        and rights to participate in a class action or to act as a Class
        Representative or Class Members in a Class Action Lawsuit, or in a
        consolidated action, in arbitration or small claims court. &nbsp;If this
        Class Action Waiver is held unenforceable, in whole or in part, as to
        any claim, then the Agreement to Arbitrate will remain applicable to
        that claim at the option of the JIPH&reg;.
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Jury Waiver</strong></li>
      </ol>
      <p style="margin-bottom: 12pt; text-align: justify">
        In the event of a court trial, TO THE FULLEST EXTENT PERMITTED BY
        APPLICABLE LAW, YOU OR THE USER HEREBY IRREVOCABLY AND EXPRESSLY WAIVE
        ALL RIGHT TO A TRIAL BY JURY IN ANY ACTION, PROCEEDING, OR COUNTERCLAIM
        (WHETHER BASED UPON CONTRACT, TORT, OR OTHERWISE) ARISING OUT OF OR
        RELATING TO THIS AGREEMENT OR THE PRIVACY POLICY OR THE JIPH&reg;
        SERVICES OR THE TRANSACTIONS CONTEMPLATED HEREBY OR THEREBY OR THE
        ACTIONS OF ANY BANK OR FINANCIAL INSTITUTION OR PROVIDER OR &nbsp;IN THE
        NEGOTIATION, ADMINISTRATION, OR ENFORCEMENT THEREOF.
      </p>
      <ol style="list-style-type: undefined">
        <li>
          <strong>Governing Law; Choice of Law; Severability</strong
          ><span style="font-size: 11px"
            ><a
              id="_anchor_48"
              href="#_msocom_48"
              language="JavaScript"
              name="_msoanchor_48"
              >[HR48]</a
            >&nbsp;</span
          >
        </li>
      </ol>
      <p style="text-align: justify">
        This Service Agreement will be governed by and construed under the laws
        of the State of Delaware, excluding only its conflict of law provisions,
        and shall inure to the benefit of JIPH&reg;, its successors and assigns,
        whether by merger, consolidation or otherwise. By using the Service, you
        hereby submit to the exclusive jurisdiction of the courts within the
        State of California and waive any jurisdictional venue or inconvenient
        forum objections to such courts (except as expressly set forth in the
        Agreement to Arbitrate, Class Action Waiver and Jury Waiver). You
        further agree to comply with any applicable federal, state and local
        laws in your use of the Service. If any provision of this Service
        Agreement is found to be unenforceable for any reason by a court of
        competent jurisdiction, that provision will be deemed to be restated to
        reflect as nearly as possible the original intentions of the parties in
        accordance with applicable law. The remaining provisions of this Service
        Agreement will not be affected thereby, and each of those provisions
        will be valid and enforceable to the full extent permitted by law.
      </p>
      <p style="margin-left: 18pt; text-align: justify; text-indent: -22.5pt">
        <strong>&nbsp;</strong>
      </p>
      <ol style="list-style-type: undefined">
        <li><strong>Customer Service</strong></li>
      </ol>
      <p style="margin-left: 18pt; text-align: justify">
        <strong>&nbsp;</strong>
      </p>
      <p style="margin-left: 36pt; text-align: justify">
        Customer Service will be provided during normal business hours unless
        otherwise offered.
      </p>
      <div id="_com_48" language="JavaScript"><br /></div>
      <div id="_com_48" language="JavaScript"><br /></div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import Navbar from "../components/navbar";
import Footer from "../components/footer";
export default {
  name: "TermsOfService",

  metaInfo: {
    title: "Terms of Service",
  },
  components: {
    navbar: Navbar,
    "footer-component": Footer,
  },
};
</script>

<style scoped>
.terms-of-service-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text1 {
  color: rgb(38, 38, 38);
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found-container2 {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text2 {
  text-align: center;
  font-weight: 400;
}
</style>
