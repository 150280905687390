import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

import LandingPage from "./views/landing-page";
import NotFound from "./views/not-found";
import TermsOfService from "./views/terms-of-service";
import PrivacyPolicy from "./views/privacy-policy";
import "./style.css";

Vue.use(Router);
Vue.use(Meta);
export default new Router({
  mode: "history",
  routes: [
    {
      name: "Landing-Page",
      path: "/",
      component: LandingPage,
    },
    {
      name: "terms-of-service",
      path: "/terms-of-service",
      component: TermsOfService,
    },
    {
      name: "privacy-policy",
      path: "/privacy-policy",
      component: PrivacyPolicy,
    },
    {
      name: "404 - Not Found",
      path: "**",
      component: NotFound,
      fallback: true,
    },
  ],
});
