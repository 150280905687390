<template>
  <div class="landing-page-container">
    <div id="hero" class="landing-page-top-container">
      <navbar pageName="landing-page"></navbar>
      <div class="landing-page-hero">
        <div class="landing-page-content-container">
          <h1 class="landing-page-text05">
            <span class="landing-page-text06">Send money</span>
            <span>
              to the Philippines
              <span v-html="raww6ph"></span>
            </span>
            <span class="landing-page-text08">instantly</span>
          </h1>
          <h2 class="landing-page-subheading">
            <span class="landing-page-text09">And it only costs you</span>
            <span class="landing-page-text10">
              <span v-html="rawyh0p"></span>
            </span>
            <span class="landing-page-text11">$1 per transaction</span>
            <span class="landing-page-text12">!</span>
          </h2>
          <h2 class="landing-page-subheading1">Available for download on:</h2>
          <div class="landing-page-container01">
            <img
              alt="image"
              src="/svg/download-apple.svg"
              class="landing-page-image01"
            />
            <img
              alt="image"
              src="/svg/download-google.svg"
              class="landing-page-image02"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="features" class="landing-page-features">
      <h1 class="landing-page-text13">
        <span>
          Pioneering the
          <span v-html="rawea1u"></span>
        </span>
        <span class="landing-page-text15">future of remittances</span>
        <br />
      </h1>
      <div class="landing-page-container02">
        <feature-card3 rootClassName="rootClassName3"></feature-card3>
        <feature-card3
          title="Competitive Rates"
          image_src="/svg/icon-money.svg"
          description="Maximize your savings with our $1 fee and best conversion rates."
          rootClassName="rootClassName"
        ></feature-card3>
        <feature-card3
          title="Security you can trust"
          image_src="/svg/icon-shield.svg"
          description="Rest easy knowing your transactions are safe and secure."
          rootClassName="rootClassName5"
        ></feature-card3>
        <feature-card3
          title="24/7 support"
          image_src="/svg/icon-support.svg"
          description="We're here for you around the clock, whenever you need assistance."
          rootClassName="rootClassName1"
        ></feature-card3>
      </div>
    </div>
    <div class="landing-page-father-daughter">
      <div class="landing-page-hero1">
        <div class="landing-page-content-container1">
          <h1 class="landing-page-text17">
            <span class="landing-page-text18">
              With
              <span v-html="raw0fcs"></span>
            </span>
            <span class="landing-page-text19">Jiph</span>
            <span class="landing-page-text20">, you can </span>
            <span class="landing-page-text21">send USD</span>
            <span class="landing-page-text22">
              to your loved ones in the Philippines
              <span v-html="rawqz1m"></span>
            </span>
            <span class="landing-page-text23">instantly</span>
          </h1>
        </div>
      </div>
    </div>
    <div id="how" class="landing-page-how-it-works">
      <div class="landing-page-heading">
        <h1 class="landing-page-text24">
          <span>How to send</span>
          <span class="landing-page-text26"> remittances</span>
          <br />
        </h1>
        <h2 class="landing-page-text28">In just a few easy steps</h2>
      </div>
      <div class="landing-page-steps">
        <div class="landing-page-step-item">
          <div class="landing-page-container03">
            <div class="landing-page-container04">
              <div class="landing-page-container05">
                <span class="landing-page-text29">1</span>
              </div>
              <h1 class="landing-page-text30">Download the app</h1>
            </div>
            <div class="landing-page-container06">
              <span class="landing-page-text31">
                Get started in seconds by downloading our app on your Android or
                iOS device.
              </span>
            </div>
          </div>
          <div class="landing-page-container07">
            <img
              alt="image"
              src="/external/step-1.svg"
              class="landing-page-image03"
            />
          </div>
        </div>
        <div class="landing-page-step-item1">
          <div class="landing-page-container08">
            <div class="landing-page-container09">
              <div class="landing-page-container10">
                <span class="landing-page-text32">2</span>
              </div>
              <h1 class="landing-page-text33">
                Register
                <span v-html="raw7b00"></span>
              </h1>
            </div>
            <div class="landing-page-container11">
              <span class="landing-page-text34">
                <span>- Create your account</span>
                <br />
                <span>
                  - Complete your profile
                  <span v-html="rawcbgk"></span>
                </span>
                <br />
                <span>- Verify your account</span>
              </span>
            </div>
          </div>
          <div class="landing-page-container12">
            <img
              alt="image"
              src="/steps/step-2-600w.png"
              class="landing-page-image04"
            />
          </div>
        </div>
        <div class="landing-page-step-item2">
          <div class="landing-page-container13">
            <div class="landing-page-container14">
              <div class="landing-page-container15">
                <span class="landing-page-text40">3</span>
              </div>
              <h1 class="landing-page-text41">
                Top up or cash in
                <span v-html="rawqkqs"></span>
              </h1>
            </div>
            <div class="landing-page-container16">
              <span class="landing-page-text42">
                Conveniently add funds using your trusted banks or e-wallets.
              </span>
            </div>
          </div>
          <div class="landing-page-container17">
            <img
              alt="image"
              src="/steps/step-3-600w.png"
              class="landing-page-image05"
            />
          </div>
        </div>
        <div class="landing-page-step-item3">
          <div class="landing-page-container18">
            <div class="landing-page-container19">
              <div class="landing-page-container20">
                <span class="landing-page-text43">4</span>
              </div>
              <h1 class="landing-page-text44">
                Send money instantly
                <span v-html="rawsqx1"></span>
              </h1>
            </div>
            <div class="landing-page-container21">
              <span class="landing-page-text45">
                <span>
                  Choose your recipient,
                  <span v-html="rawl62i"></span>
                </span>
                <br />
                <span>enter the amount, and hit send. </span>
                <br />
                <span>Your loved ones receive it instantly!</span>
              </span>
            </div>
          </div>
          <div class="landing-page-container22">
            <img
              alt="image"
              src="/steps/step-4-600w.png"
              class="landing-page-image06"
            />
          </div>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import SolidButton from "../components/solid-button";
import FeatureCard3 from "../components/feature-card3";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

export default {
  name: "LandingPage",
  components: {
    SolidButton,
    FeatureCard3,
    navbar: Navbar,
    "footer-component": Footer,
  },
  data() {
    return {
      rawwxl1: " ",
      raww6ph: " ",
      rawyh0p: " ",
      rawea1u: " ",
      raw0fcs: " ",
      rawqz1m: " ",
      raw7b00: " ",
      rawcbgk: " ",
      rawqkqs: " ",
      rawsqx1: " ",
      rawl62i: " ",
      raw6dy7: " ",
      rawfj3k: " ",
      rawip7m: " ",
      rawxrig: " ",
      rawgmzy: " ",
      rawvovy: " ",
      rawckfe: " ",
      rawaks7: " ",
      raw3272: " ",
      rawytx6: " ",
      raw30k7: " ",
      rawjw9h: " ",
      rawifsx: " ",
      rawv58m: " ",
      rawvwll: " ",
      rawq8pd: " ",
      raw7pq0: " ",
      rawokbc: " ",
    };
  },
  metaInfo: {
    title: "Jiph | Send Money to the Philippines!",
    meta: [
      {
        name: "description",
        content:
          "The fastest way to send money to the Philippines. Tired of Waiting? Cut the line with instant transfers from your mobile device. All for just $1.",
      },
      {
        property: "og:title",
        content: "Jiph | Send Money to the Philippines!",
      },
      {
        property: "og:description",
        content:
          "The fastest way to send money to the Philippines. Tired of Waiting? Cut the line with instant transfers from your mobile device. All for just $1.",
      },
    ],
  },
};
</script>
