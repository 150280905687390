<template>
  <div class="feature-card3-feature-card" v-bind:class="rootClassName">
    <div class="feature-card3-container">
      <img :alt="image_alt" :src="image_src" class="feature-card3-image" />
    </div>
    <h2 class="feature-card3-text">{{ title }}</h2>
    <span class="feature-card3-text1">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard3',
  props: {
    title: {
      type: String,
      default: 'Instant transfers',
    },
    action: {
      type: String,
      default: 'SEE MORE',
    },
    description: {
      type: String,
      default:
        'Say goodbye to waiting. Your money arrives in seconds, not days.',
    },
    rootClassName: String,
    image_src: {
      type: String,
      default: '/svg/icon-lightning.svg',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.feature-card3-feature-card {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.feature-card3-container {
  flex: 0 0 auto;
  width: auto;
  height: 96px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.feature-card3-image {
  width: 96px;
  height: 96px;
  object-fit: cover;
}
.feature-card3-text {
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.feature-card3-text1 {
  color: var(--dl-color-gray-700);
  font-size: 14px;
  text-align: center;
}








</style>
